import { initScrollListener } from "./js/init-scroll-listener";
import { notify } from "./js/notify";

import {
  FORM_CLASS,
  FORM_INPUT_CLASS,
  INPUT_VALIDATION_ERROR_CLASS,
} from "./js/constants";

function onFormSubmit(event) {
  event.preventDefault();

  const form = event.currentTarget;
  const input = form.querySelector(`.${FORM_INPUT_CLASS}`);

  if (validateEmail(input.value)) {
    sendEmail(input.value).then(() => {
      input.value = "";
      notify(form);
    });
  } else {
    input.classList.add(INPUT_VALIDATION_ERROR_CLASS);
  }
}

function main() {
  const forms = document.querySelectorAll(`.${FORM_CLASS}`);
  Array.from(forms).forEach((form) => {
    form.addEventListener("submit", onFormSubmit);
  });
  window.addEventListener("scroll", initScrollListener());

  document.addEventListener("input", (event) => {
    const input = event.target;
    if (input.classList.contains(INPUT_VALIDATION_ERROR_CLASS)) {
      input.classList.remove(INPUT_VALIDATION_ERROR_CLASS);
    }
  });
  document?.getElementById("zero_btn")?.addEventListener("click", () => {
    location.href = parseHostname("/register?s_p=0");
  });
  document?.getElementById("first_btn")?.addEventListener("click", () => {
    location.href = parseHostname("/register?s_p=1");
  });
  document?.getElementById("second_btn")?.addEventListener("click", () => {
    location.href = parseHostname("/register?s_p=2");
  });
  document?.querySelector(".login-button")?.addEventListener("click", () => {
    location.href = parseHostname();
  });
  document
    ?.querySelector("[data-selector=login-button]")
    ?.addEventListener("click", () => {
      location.href = parseHostname();
    });
  document?.querySelectorAll(".second-btn").forEach((e) =>
    e.addEventListener("click", () => {
      location.href = parseHostname("/register?s_p=2");
    })
  );
  function parseHostname(suffix = "") {
    let path = document.location.hostname;
    if (path.startsWith("www.")) {
      let parts = path.split(".");
      parts[0] = "app";
      path = parts.join(".");
    } else if (path.startsWith("localhost")) {
      path = "localhost:3000";
    } else {
      path = "app." + path;
    }
    const protocol = document.location.protocol + "//";
    return protocol + path + suffix;
  }
}

window.addEventListener("DOMContentLoaded", main);
